import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import SelectCustomerModal from "src/main/PointOfSale/CurrentSalePanel/SelectCustomerModal"
import ServerCartItem from "src/main/PointOfSale/CurrentSalePanel/ServerCartItem"
import { POSContext } from "src/main/PointOfSale/Layout"

import Button from "src/components/Button"
import Heading from "src/components/Heading"
import LineItems from "src/components/LineItems/LineItems"

import { useModalToggle } from "src/hooks/use_modal_toggle"

import PanelWrapper from "../PanelWrapper"
import CartItem from "./CartItem"
import CurrentSaleTotals from "./CurrentSaleTotals"
import SaleNote from "./SaleNote"
import SoldBySelector from "./SoldBySelector"

const CurrentSalePanel = ({
  disableFields = false,
  navigationButton,
  hideNavigationButton = false,
  sale,
}) => {
  const navigate = useNavigate()
  const [isCustomerModalOpen, showCustomerModal, hideCustomerModal] =
    useModalToggle()

  const {
    cartController: {
      methods,
      fields,
      closeAllPanels,
      lineItems,
      loading,
      contact,
      cartStatus,
    },
    serverCartFeatureEnabled,
  } = useContext(POSContext)

  const isServerCartCompleted =
    serverCartFeatureEnabled && cartStatus === "completed"

  const renderCartItem = (item, index) => {
    return serverCartFeatureEnabled ? (
      <ServerCartItem
        key={item.id}
        item={item}
        index={index}
        disabled={disableFields}
      />
    ) : (
      <CartItem
        key={item.id}
        item={item}
        index={index}
        disabled={disableFields}
      />
    )
  }

  const renderNavigationButton = () => {
    if (hideNavigationButton) return null

    if (navigationButton) {
      return (
        <Button
          variant="tertiary"
          onClick={() => navigate(navigationButton.path)}
          fullWidth
          disabled={isServerCartCompleted}
        >
          {navigationButton.name}
        </Button>
      )
    } else {
      return (
        <Button
          variant="primary"
          onClick={() => {
            closeAllPanels()
            navigate("/checkout")
          }}
          fullWidth
          disabled={fields.length === 0 || !methods.formState.isValid}
        >
          Checkout
        </Button>
      )
    }
  }

  function renderHeader() {
    if (serverCartFeatureEnabled) {
      return (
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-4">
            <Heading.ModalTitle>Current Sale</Heading.ModalTitle>
            <SoldBySelector disabled={disableFields} />
          </div>
          <Button
            className="flex-none"
            icon={contact ? "icon-person-outline" : null}
            variant={contact ? "secondary" : "tertiary"}
            onClick={showCustomerModal}
            disabled={isServerCartCompleted}
          >
            {contact ? contact.name : "Add Customer"}
          </Button>
        </div>
      )
    } else {
      return (
        <>
          <Heading.ModalTitle>Current Sale</Heading.ModalTitle>
          <SoldBySelector disabled={disableFields} />
        </>
      )
    }
  }

  return (
    <>
      <PanelWrapper justifyBetween>
        {renderHeader()}
        <div className="flex h-4/5 flex-col justify-start divide-y">
          <div className="flex-1 flex-initial overflow-y-auto pb-4 pr-2 pt-6">
            <div className="space-y-2">{fields.map(renderCartItem)}</div>
            <SaleNote disabled={disableFields} />
          </div>
          {serverCartFeatureEnabled ? (
            <div className="py-4">
              <LineItems lineItems={lineItems} loading={loading} />
            </div>
          ) : (
            <CurrentSaleTotals sale={sale} />
          )}
        </div>
        <div className="flex h-16 items-start">{renderNavigationButton()}</div>
        <SelectCustomerModal
          isOpen={isCustomerModalOpen}
          onClose={hideCustomerModal}
        />
      </PanelWrapper>
    </>
  )
}

CurrentSalePanel.propTypes = {
  disableFields: PropTypes.bool,
  navigationButton: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  hideNavigationButton: PropTypes.bool,
  sale: PropTypes.object,
}
export default CurrentSalePanel
