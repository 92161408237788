import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { POSContext } from "src/main/PointOfSale/Layout"

import Heading from "src/components/Heading"

import PanelWrapper from "../PanelWrapper"
import PaymentMethodButton from "./PaymentMethodButton"
import { CheckoutContext } from "./index"
import { Cash } from "./payment_flows/cash"
import { Check } from "./payment_flows/check"
import { CreditCard } from "./payment_flows/credit_card"
import { ManualCreditCard } from "./payment_flows/manual_credit_card"
import { PayLater } from "./payment_flows/pay_later"
import { SavedPaymentMethod } from "./payment_flows/saved_payment_method"

const PaymentMethodPanel = ({ launchPaymentFlow, showHeader = true }) => {
  const {
    serverCartFeatureEnabled,
    cartController: {
      contact,
      totals: { total },
      cartStatus,
    },
  } = useContext(POSContext)
  const { sale: retrySale, noCardReadersConfigured } =
    useContext(CheckoutContext)

  const isRetry = !!retrySale
  const isCompleted = serverCartFeatureEnabled && cartStatus === "completed"
  const isGuestSale = contact === null
  const zeroTotalCart = new Decimal(total).toNumber() === 0
  const cardReaderDisabledMsg = noCardReadersConfigured
    ? "Configure a card reader in your settings to enable this payment method."
    : null
  const zeroTotalCartDisabledMsg = zeroTotalCart
    ? "Payment not accepted for a $0 sale."
    : null

  function getCashDisabledMessage() {
    return isRetry && retrySale?.tip
      ? "Cash payment cannot be used to retry a sale with a tip."
      : zeroTotalCartDisabledMsg
  }

  function getCardReaderDisabledMessage() {
    return cardReaderDisabledMsg || zeroTotalCartDisabledMsg
  }

  function getCheckDisabledMessage() {
    return isRetry && retrySale?.tip
      ? "Check payment cannot be used to retry a sale with a tip."
      : zeroTotalCartDisabledMsg
  }

  function getPayLaterDisabledMessage() {
    return isRetry || isCompleted
      ? "Pay Later cannot be used to retry a sale."
      : null
  }

  function getSavedPaymentDisabledMessage() {
    return isCompleted && isGuestSale
      ? "Saved Payment cannot be used to retry a guest sale."
      : zeroTotalCartDisabledMsg
  }

  function getManualCreditCardDisabledMessage() {
    return zeroTotalCartDisabledMsg
  }

  return (
    <PanelWrapper>
      {showHeader ? (
        <div className="mb-4">
          <Heading.SectionHeader>Payment Method</Heading.SectionHeader>
        </div>
      ) : null}
      <div className="grid grid-cols-2 gap-2 rounded bg-gray-100 p-5 md:grid-cols-3 md:gap-6">
        <PaymentMethodButton
          disabledMessage={getCardReaderDisabledMessage()}
          name="Credit Card Reader"
          icon="icon-pos-credit-card"
          onClick={() =>
            launchPaymentFlow({
              paymentFlow: CreditCard,
            })
          }
        />
        <PaymentMethodButton
          disabledMessage={getSavedPaymentDisabledMessage()}
          name="Saved Payment"
          icon="icon-pos-saved-payment"
          onClick={() =>
            launchPaymentFlow({
              paymentFlow: SavedPaymentMethod,
            })
          }
        />
        <PaymentMethodButton
          disabledMessage={getCashDisabledMessage()}
          name="Cash"
          icon="icon-pos-cash"
          onClick={() =>
            launchPaymentFlow({
              paymentFlow: Cash,
            })
          }
        />
        <PaymentMethodButton
          disabledMessage={getCheckDisabledMessage()}
          name="Check"
          icon="icon-pos-check"
          onClick={() =>
            launchPaymentFlow({
              paymentFlow: Check,
            })
          }
        />
        <PaymentMethodButton
          disabledMessage={getPayLaterDisabledMessage()}
          name="Pay Later"
          icon="icon-pos-pay-later"
          onClick={() =>
            launchPaymentFlow({
              paymentFlow: PayLater,
            })
          }
        />
        <PaymentMethodButton
          disabledMessage={getManualCreditCardDisabledMessage()}
          name="Credit Card (Manual)"
          icon="icon-pos-manual-credit-card"
          onClick={() =>
            launchPaymentFlow({
              paymentFlow: ManualCreditCard,
            })
          }
        />
      </div>
    </PanelWrapper>
  )
}
PaymentMethodPanel.propTypes = {
  launchPaymentFlow: PropTypes.func.isRequired,
  showHeader: PropTypes.bool,
}

export default PaymentMethodPanel
